import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { COLORS } from "../../../../SupportingFiles/colors";
import "./MFooter.scss";

function MFooter() {
  const navigate = useNavigate();
  return (
    <Box className="mfooter">
      <Box className="top-sec">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box textAlign={"center"} marginBottom={"24px"}>
              <img
                src="./images/muthoot-logo.svg"
                height="60px"
                alt="Muthoot Insurance"
                onClick={() => {}}
                style={{ cursor: "pointer" }}
              />
            </Box>
          </Grid>
          <Grid xs={6} marginBottom={"16px"}>
            <h6 className="mb-3">Address</h6>
            <p>
              Muthoot Insurance Brokers Private Limited <br /> 3rd Floor,
              Muthoot Chambers, Banerji Road, Opp. Saritha Theatre, Ernakulam,
              Kerala - 682018
            </p>
          </Grid>
          <Grid xs={6} marginBottom={"16px"}>
            <h6 className="mb-3">Products</h6>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                  }}
                >
                  Health insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_CAR);
                  }}>
                  Car insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TW);
                  }}>
                  Two-Wheeler insurance
                </Link>
              </li>
              {/* 
              <li>
                <Link>Term insurance</Link>
              </li> */}
            </ul>
          </Grid>
          <Grid xs={6} marginBottom={"16px"}>
            <h6 className="mb-4">Company</h6>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT_US);
                  }}>
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                  }}>
                  Terms & Conditions
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRIVACY_POLICY);
                  }}>
                  Privacy Policy
                </Link>
              </li> */}
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.DISCLAIMER);
                  }}>
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.REFUND_CANCELATION);
                  }}>
                  Refund & Cancellation
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.GRIEVANCE_POLICY);
                  }}>
                  Grievance Policy
                </Link>
              </li>
              {/* <li>
                <Link>Site Map</Link>
              </li> */}
            </ul>
          </Grid>
          <Grid xs={6} marginBottom={"16px"}>
            <h6 className="mb-3">Services</h6>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CLAIM);
                  }}>
                  Claim
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.COMPLAINT);
                  }}>
                  Complaint
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CAREER);
                  }}>
                  Career
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CONTACT);
                  }}>
                  Contact
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid xs={12} marginBottom={"16px"}>
            <h6 className="mb-4">Contact Us</h6>
            <ul>
              <li>
                <p>
                  Principal Officer: <span>Suresh K</span>
                </p>
              </li>
              <li>
                <p>
                  Mail:{" "}
                  <a href="mailto:principalofficer@muthootgroup.com">
                    principalofficer@muthootgroup.com
                  </a>
                </p>
              </li>
              <li>
                <p>
                  IRDAI License No: <span>459 </span>
                </p>
              </li>
              <li>
                <p>
                  License Validity:{" "}
                  <span>
                    <span style={{ display: "inline-block" }}>02-09-2022</span>{" "}
                    -{" "}
                    <span style={{ display: "inline-block" }}>01-09-2025</span>
                  </span>
                </p>
              </li>
              <li>
                <p>
                  CIN No: <span>U67200KL2002PTC015200</span>
                </p>
              </li>
              <li>
                <p>
                  IBAI Membership No: <span>43459</span>
                </p>
              </li>
              {/* <li>
                <Box className="social-icons">
                  <Link>
                    <img src="./images/fb-icon.svg" alt="" />
                  </Link>
                  <Link>
                    <img src="./images/linkedin-icon.svg" alt="" />
                  </Link>
                  <Link>
                    <img src="./images/youtube-icon.svg" alt="" />
                  </Link>
                </Box>
              </li> */}
            </ul>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} textAlign={"center"}>
            <p
              style={{
                display: "inline-block",
                color: "rgba(37, 37, 37, 1)",
                padding: "8px 16px",
                borderRadius: "24px",
                background: COLORS.white,
                fontSize: "10px",
                lineHeight: "16px",
              }}>
              Insurance is the subject matter of the solicitation. For more
              details on policy terms, conditions, exclusions, limitations,
              please refer/read policy brochure carefully before concluding
              sale.
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box className="copyright-bar">
        <p>
          © Copyright {new Date().getFullYear()} Muthoot Insurance Brokers
          Private Limited. All Rights Reserved.
        </p>
      </Box>
    </Box>
  );
}

export default MFooter;
