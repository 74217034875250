import { PayloadAction } from "@reduxjs/toolkit";
import { TDropdown } from "../../../../Type/Common/TDropdown";
import { TTermAddForm } from "../../../../Type/Term/TTermAddForm";
import { DROPDOWN_MASTER, TTermSlice } from "../../../../Type/Term/TTermSlice";

function BULK_UPDATE(state: TTermSlice, action: PayloadAction<TTermSlice>) {
  const data: TTermSlice = { ...state, ...action.payload };

  return data;
}

function UPDATE_DROPDOWN_DATA(
  state: TTermSlice,
  action: PayloadAction<{ key: keyof DROPDOWN_MASTER; value: TDropdown[] }>
) {
  const { key, value } = action.payload;
  let data: TTermSlice = { ...state };

  data = { ...data, DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value } };

  return data;
}

function UPDATE_ADD_FORM_DATA(
  state: TTermSlice,
  action: PayloadAction<TTermAddForm>
) {
  const data: TTermSlice = { ...state, ADD_FORM: { ...action.payload } };

  return data;
}

function SET_DROPDOWN_DATA(
  state: TTermSlice,
  action: PayloadAction<{ key: keyof DROPDOWN_MASTER; value: TDropdown[] }>
) {
  const { key, value } = action.payload;
  let data: TTermSlice = { ...state };

  data = {
    ...data,
    DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value },
  };

  return data;
}
export const TERM_REDUCERS = {
  BULK_UPDATE,
  UPDATE_DROPDOWN_DATA,
  SET_DROPDOWN_DATA,
  UPDATE_ADD_FORM_DATA,
};

export type TwoTermReducers = typeof TERM_REDUCERS;
