import { createSlice } from "@reduxjs/toolkit";
import { TTermSlice } from "../../../Type/Term/TTermSlice";
import { TERM_REDUCERS } from "./Reducer/TermReducer";

const initialState: TTermSlice = {
  DROPDOWN_DATA: {
    ANNUAL_INCOME: [],
    INCOME_PERIOD: [],
    GENDER: [
      { value: "M", label: "Male" },
      { value: "F", label: "Female" },
    ],
    SMOKE: [
      { value: "Y", label: "Yes" },
      { value: "N", label: "No" },
    ],
    AGE: [],
    PAY_MODE_DATA: [
      { label: "12", value: "Monthly" },
      { label: "4", value: "Quarterly" },
      { label: "2", value: "Half-yearly" },
      { label: "1", value: "Yearly" },
      { label: "5", value: "Single Pay" },
    ],
    COVERAGE_UPTO: [],
    PAY_UPTO: [],
    SORT_BY: [
      { label: "Low-High", value: "Low-High" },
      { label: "High-Low", value: "High-Low" },
    ],
    MONTHLY_PAYOUT: [],
    SUM_ASSURED: [],
    OCCUPATION_DATA: [],
    QUALIFICATION_DATA: [],
  },
  ADD_FORM: {
    annualIncome: { value: "", warning: false },
    smoke: { value: "", warning: false },
    gender: { value: "", warning: false },
    age: { value: "", warning: false },
    pincode: { value: "", warning: false },
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
  },
};

const ageData = [];
for (let i = 18; i <= 65; i++) {
  ageData.push({
    label: `${i}`,
    value: `${i} Years`,
  });
}

initialState.DROPDOWN_DATA.AGE = ageData;

let incomePeriodData = [];
for (let i = 1; i <= 30; i++) {
  incomePeriodData.push({
    label: `${i}`,
    value: `${i} Years`,
  });
}

initialState.DROPDOWN_DATA.INCOME_PERIOD = incomePeriodData;

let annual_income_data = [];
for (let i = 2; i <= 100; i++) {
  const value = `${i * 100000}`;
  let label = "";
  if (i < 100) {
    label = `₹ ${i} lakh`;
  } else {
    const croreValue = i / 100;
    label = `₹ ${croreValue} crore`;
  }
  annual_income_data.push({ label, value });
}

initialState.DROPDOWN_DATA.ANNUAL_INCOME = annual_income_data;

export const TermSlice = createSlice({
  name: "Term",
  initialState,
  reducers: TERM_REDUCERS,
});

export default TermSlice.reducer;
