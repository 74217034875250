import React, { useState } from "react";
import { TERM_SERVICES } from "../../Services/Term/TermServices";
import {
  FORMAT_DD_MM_YYYY,
  calculateDOBFromAge,
  // calculateDOBFromAge,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../SupportingFiles/HelpingFunction";
import { TermSlice } from "../../Store/Reducer/Term/TermSlice";
import { TTermAddForm } from "../../Type/Term/TTermAddForm";
import { useAppDispatch, useAppSelector } from "../../Store/Store/hooks";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import TermsProductPage from "../../ProductFormPages/TermsProductPage/TermsProductPage";
import { AddTermDTO } from "../../Services/Term/TermDTO";
import { FRONTEND_DOMAIN } from "../../Routing/CommonURLs/Domain";
import { TERM_ROUTES } from "../../Routing/Path/TermRoutes";
import { toast } from "react-toastify";

const TermProductContainer = () => {
  const isMobile = useIsMobile();
  const { ADD_FORM } = useAppSelector((state) => state.Term);
  const { Term } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);

  const [formData, setFormData] = useState<TTermAddForm>(ADD_FORM);
  const dispatch = useAppDispatch();

  const annual_income_data: { label: string; value: string }[] = [];
  console.log("formDa", formData);
  // Generate labels and values for lakhs from 2 lakh to 1 crore
  for (let i = 2; i <= 100; i++) {
    const value = `${i * 100000}`;
    let label = "";
    if (i < 100) {
      label = `₹ ${i} lakh`;
    } else {
      const croreValue = i / 100;
      label = `₹ ${croreValue} crore`;
    }
    annual_income_data.push({ label, value });
  }
  const age_data: { label: string; value: string }[] = [];
  for (let i = 18; i <= 65; i++) {
    age_data.push({ label: `${i} years`, value: `${i}` });
  }
  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "pincode"
            ? !validatePincode(value)
            : attrName === "mobile"
            ? !validateMobileNumber(value)
            : isEmpty(value),
      },
    }));
  };

  const validate_form = () => {
    let hasError = false;
    let data: TTermAddForm = { ...formData };
    data = {
      ...data,
      annualIncome: {
        ...data.annualIncome,
        warning: isEmpty(data.annualIncome.value),
      },
      smoke: {
        ...data.smoke,
        warning: isEmpty(data.smoke.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
      age: {
        ...data.age,
        warning: isEmpty(data.age.value),
      },
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
    };
    if (
      data.annualIncome.warning ||
      data.smoke.warning ||
      data.gender.warning ||
      data.age.warning ||
      data.pincode.warning ||
      data.mobile.warning
    ) {
      hasError = true;
    }
    if (!hasError) {
      add_form(data);
    }
    setFormData({ ...data });
  };

  const add_form = (data: TTermAddForm) => {
    const onSuccess = (res: any) => {
      const results = res;
      const response = results?.response;
      const error = results?.error;
      if (!error) {
        const quote_no = response?.quote_no;
        window.location.href = `${FRONTEND_DOMAIN}${TERM_ROUTES.QUOTE}?type=TM&quote_no=${quote_no}`;
      } else {
        toast.error(`${results?.message}`);
      }

      setLoader(false);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setLoader(false);
    };
    const ageInDays = parseInt(data.age.value, 10);
    const dob = !isNaN(ageInDays)
      ? FORMAT_DD_MM_YYYY(calculateDOBFromAge(ageInDays))
      : "";

    let params: AddTermDTO = {
      lifeInsurance_module: "TermPlan",
      lifeInsurance_type: "Term Plan",
      termSmoker: data.smoke.value === "Yes" ? "Y" : "N",
      termAnnualIncome: `${data.annualIncome.value}`,
      termAnnualIncomeOther: `${data.annualIncome.value}`,
      termGender: data.gender.value === "Male" ? "M" : "F",
      termDob: `${FORMAT_DD_MM_YYYY(data.age.value)}`,
      termName: data.name.value,
      termMobile: data.mobile.value,
      termPincode: data.pincode.value,
    };
    console.log("data.age.value", data.age.value);

    setLoader(true);
    TERM_SERVICES.addTermFormData(onSuccess, onError, params);
  };
  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const smokinStatus_data = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <>
      <TermsProductPage
        annual_income_data={annual_income_data}
        age_data={age_data}
        gender_data={gender_data}
        smokinStatus_data={smokinStatus_data}
        updateMasterState={updateMasterState}
        validate_form={validate_form}
        loader={loader}
        formData={formData}
      />
    </>
  );
};

export default TermProductContainer;
