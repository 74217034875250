import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";

function RefundCancellation() {
  const isMobile = useIsMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox refundCancellation">
              <h1 style={{ textAlign: "center" }}>Refund & Cancellation</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="textContent-pages">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <p>
              Policy cancellation and refund of the premium shall be as per the
              terms & conditions of the policy. The refunds are processed by the
              Insurance Company directly. You are requested to contact the toll
              free number of your Insurance Company or refer the respective
              section of your Policy terms and conditions.
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default RefundCancellation;
