import { PayloadAction } from "@reduxjs/toolkit";
import {
  THealthAddForm,
  THealthSlice,
} from "../../../Type/Health/THealthSlice/THealthSlice";

function BULK_UPLOAD(state: THealthSlice, action: PayloadAction<THealthSlice>) {
  const data: THealthSlice = { ...state, ...action.payload };

  return data;
}

function setAddFormData(
  state: THealthSlice,
  action: PayloadAction<THealthAddForm>
) {
  return {
    ...state,
    ADD_FORM: { ...action.payload },
  };
}

function updateMultipleKeysInAddForm(
  state: THealthSlice,
  action: PayloadAction<Partial<THealthSlice["ADD_FORM"]>>
) {
  state.ADD_FORM = {
    ...state.ADD_FORM,
    ...action.payload,
  };
}

export const HEALTH_REDUCERS = {
  BULK_UPLOAD,
  setAddFormData,
  updateMultipleKeysInAddForm,
};

export type HealthReducers = typeof HEALTH_REDUCERS;
