import React from "react";

interface LoaderProps {
  size: "small" | "large"; // Define size prop
}

const Loader: React.FC<LoaderProps> = ({ size }) => {
  // Define size-related properties
  const strokeWidth = size === "small" ? 4 : 7;
  const r = size === "small" ? 10 : 20.5;
  const strokeDasharray = size === "small" ? 100 : 160;
  const animationDuration = size === "small" ? "3s" : "2.5s"; // Set animation duration

  const progressAnimation: React.CSSProperties = {
    animation: `progressAnimation ${animationDuration} linear infinite`, // Use animation duration based on size
  };

  return (
    <svg width={(r + strokeWidth) * 2} height={(r + strokeWidth) * 2}>
      <circle
        fill="none"
        strokeWidth={`${strokeWidth}px`}
        stroke="#E0E0E0"
        cx={r + strokeWidth}
        cy={r + strokeWidth}
        r={r}
      ></circle>
      <circle
        className="progress"
        fill="none"
        strokeWidth={`${strokeWidth}px`}
        stroke="#DF9F39"
        strokeLinecap="round"
        strokeDasharray={strokeDasharray}
        cx={r + strokeWidth}
        cy={r + strokeWidth}
        r={r}
        style={{
          ...progressAnimation,
          transform: "rotate(-90deg)",
          transformOrigin: "50%",
        }}
      ></circle>
      <style>
        {`
          @keyframes progressAnimation {
            0% {
              stroke-dashoffset: ${strokeDasharray};
            }
            50% {
              stroke-dashoffset: 0;
            }
            100% {
              stroke-dashoffset: -${strokeDasharray};
            }
          }
        `}
      </style>
    </svg>
  );
};

export default Loader;
