import React from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays, subYears } from "date-fns";
import { useEffect, useState } from "react";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { THealthAddForm } from "../../Type/Health/THealthSlice/THealthSlice";
import { useAppSelector } from "../../Store/Store/hooks";
import { COMMON_ROUTES } from "../../Routing/Path/CommonRoutes";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../SupportingFiles/colors";
import RKTextField from "../../Component/InputFields/RKTextField/RKTextField";
import DatePicker from "../../Component/InputFields/DatePicker/DatePicker";
import CustomButton from "../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import SelectDropdown from "../../Component/InputFields/SelectDropdown/SelectDropdown";

const HealthForm = ({
  pageStatus,
  updateMasterState,
  formData,
  validateForm,
  backFunction,
  loading,
  relationship_data,
  ADD_FORM_DETAILS,
}: {
  pageStatus: "1" | "2";
  updateMasterState: Function;
  formData: THealthAddForm;
  validateForm: Function;
  backFunction: Function;
  loading: boolean;
  relationship_data: any;
  ADD_FORM_DETAILS: Function;
}) => {
  const DATA = useAppSelector((state: any) => state.CommonDropdownData);

  // Screen height is more than add space on top
  const [addSpacing, setAddSpacing] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;
      setAddSpacing(screenHeight > 700);
    };
    // Initial check on mount
    handleResize();
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log("pageStatus", DATA);

  return (
    <Box>
      {window.location.pathname !== COMMON_ROUTES.PRODUCT_HEALTH ||
      pageStatus !== "1" ? (
        <Grid container columnSpacing={3} alignItems={"center"}>
          <Grid xs={12}>
            <Link
              sx={{
                position: "absolute",
                top: "6px",
                left: "6px",
                color: COLORS.black,
                display: "inline-block",
              }}
              onClick={() => backFunction()}
            >
              <ChevronLeftRoundedIcon sx={{ fontSize: "30px" }} />
            </Link>
          </Grid>
        </Grid>
      ) : null}
      <div className="formWrapper">
        {pageStatus === "1" ? (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
            className="row"
            sx={{ justifyContent: "center" }}
          >
            <Grid xs={12}>
              <p
                className="toinsure"
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Select the family members you want to insure
              </p>
            </Grid>
            <Grid xs={6}>
              <Box
                sx={{
                  border: "1px solid #BDBDBD",
                  borderRadius: "8px",
                  padding: "8px 10px",
                  position: "relative",
                }}
                className="self-selectField"
              >
                <CustomCheckbox
                  isChecked={!formData.spouse_status && formData.self_status}
                  label="One Adult"
                  attrName="spouse_status"
                  value_update={() => updateMasterState("spouse_status", false)}
                  value={!formData?.spouse_status}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box
                sx={{
                  border: "1px solid #BDBDBD",
                  borderRadius: "8px",
                  padding: "8px 10px",
                }}
              >
                <CustomCheckbox
                  isChecked={formData.spouse_status}
                  label="Two Adults"
                  attrName="spouse_status"
                  value_update={() => updateMasterState("spouse_status", true)}
                  value={formData?.spouse_status}
                />
              </Box>
            </Grid>
            <Grid xs={12} md={6}>
              <Box
                sx={{
                  border: "1px solid #BDBDBD",
                  borderRadius: "8px",
                  padding: "8px 10px",
                  position: "relative",
                }}
                className="self-selectField"
              >
                <CustomCheckbox
                  label="Son "
                  attrName={"son_status"}
                  value={formData?.son_status}
                  value_update={updateMasterState}
                  isChecked={formData?.son_status}
                />
                {formData.son_status && (
                  <Box
                    position="absolute"
                    right="8px"
                    top="12px"
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    zIndex="9"
                  >
                    <Box
                      onClick={() => updateMasterState("son_subtract", "")}
                      height="16px"
                      width="16px"
                      color={COLORS.primary}
                      textAlign="center"
                      sx={{ cursor: "pointer" }}
                    >
                      <RemoveRoundedIcon
                        sx={{ width: "16px", height: "16px" }}
                      />
                    </Box>
                    <Box
                      height="16px"
                      width="16px"
                      color={COLORS.primary}
                      borderRadius="40px"
                      textAlign="center"
                      sx={{
                        backgroundColor: "rgba(255, 243, 244, 1)",
                        fontSize: "12px",
                      }}
                    >
                      {formData.son_count.value}
                    </Box>
                    <Box
                      onClick={() => updateMasterState("son_add", "")}
                      height="16px"
                      width="16px"
                      color={COLORS.primary}
                      textAlign="center"
                      sx={{ cursor: "pointer" }}
                    >
                      <AddRoundedIcon sx={{ width: "16px", height: "16px" }} />
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid xs={12} md={6}>
              <Box
                sx={{
                  border: "1px solid #BDBDBD",
                  borderRadius: "8px",
                  padding: "8px 10px",
                  position: "relative",
                }}
              >
                <CustomCheckbox
                  label="Daughter "
                  attrName={"daughter_status"}
                  value={formData?.daughter_status}
                  value_update={updateMasterState}
                  isChecked={formData?.daughter_status}
                />
                {formData.daughter_status && (
                  <Box
                    position="absolute"
                    right="8px"
                    top="12px"
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    zIndex="9"
                  >
                    <Box
                      onClick={() => updateMasterState("daughter_subtract", "")}
                      height="16px"
                      width="16px"
                      color={COLORS.primary}
                      textAlign="center"
                      sx={{ cursor: "pointer" }}
                    >
                      <RemoveRoundedIcon
                        sx={{ width: "16px", height: "16px" }}
                      />
                    </Box>
                    <Box
                      height="16px"
                      width="16px"
                      color={COLORS.primary}
                      borderRadius="40px"
                      textAlign="center"
                      sx={{
                        backgroundColor: "rgba(255, 243, 244, 1)",
                        fontSize: "12px",
                      }}
                    >
                      {formData.daughter_count.value}
                    </Box>
                    <Box
                      onClick={() => updateMasterState("daughter_add", "")}
                      height="16px"
                      width="16px"
                      color={COLORS.primary}
                      textAlign="center"
                      sx={{ cursor: "pointer" }}
                    >
                      <AddRoundedIcon sx={{ width: "16px", height: "16px" }} />
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid xs={12}>
              <p className="toinsure" style={{ textAlign: "center" }}>
                Select age of your family members
              </p>
            </Grid>

            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"1st Adult DOB"}
                value={formData.self_dob.value}
                attrName={"self_dob"}
                value_update={updateMasterState}
                warn_status={formData.self_dob.warning}
                error_message={"Select 1st Adult DOB"}
                min_date={110}
                max_date={18}
                date_validation_type="YEARS"
                default_date={subYears(new Date(), 18)}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={formData.gender.value}
                attrName={"gender"}
                value_update={updateMasterState}
                data={[
                  { label: "Male", value: "M" },
                  { label: "Female", value: "F" },
                ]}
                warn_status={formData.gender.warning}
                error_message={"Select Gender"}
              />
            </Grid>
            {formData.spouse_status && (
              <>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title={"2nd Adult DOB"}
                    value={formData.spouse_dob.value}
                    attrName={"spouse_dob"}
                    value_update={updateMasterState}
                    warn_status={formData.spouse_dob.warning}
                    error_message={"Select 2nd Adult DOB"}
                    min_date={110}
                    max_date={18}
                    date_validation_type="YEARS"
                    default_date={subYears(new Date(), 18)}
                  />
                </Grid>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title={"Relation with 1st Adult"}
                    attrName={"spouse_relationship"}
                    value={formData?.spouse_relationship?.value}
                    value_update={updateMasterState}
                    data={relationship_data}
                    warn_status={formData.spouse_relationship.warning}
                    error_message={"Select Relationship"}
                  />
                </Grid>
              </>
            )}

            {parseInt(formData.son_count.value) +
              parseInt(formData.daughter_count.value) >=
              1 && (
              <>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title={"Child 1 DOB"}
                    value={formData?.child_one_dob?.value}
                    attrName={"child_one_dob"}
                    value_update={updateMasterState}
                    warn_status={formData?.child_one_dob?.warning}
                    error_message={"Select 1st Child DOB"}
                    min_date={25 * 12}
                    max_date={3}
                    date_validation_type="MONTHS"
                    default_date={subDays(new Date(), 90)}
                  />
                </Grid>
              </>
            )}

            {parseInt(formData.son_count.value) +
              parseInt(formData.daughter_count.value) >=
              2 && (
              <>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title={"Child 2 DOB"}
                    value={formData?.child_two_dob?.value}
                    attrName={"child_two_dob"}
                    value_update={updateMasterState}
                    warn_status={formData?.child_two_dob?.warning}
                    error_message={"Select 2nd Child DOB"}
                    min_date={25 * 12}
                    max_date={3}
                    date_validation_type="MONTHS"
                    default_date={subDays(new Date(), 90)}
                  />
                </Grid>
              </>
            )}
            {parseInt(formData.son_count.value) +
              parseInt(formData.daughter_count.value) >=
              3 && (
              <>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title={"Child 3 DOB"}
                    value={formData?.child_three_dob?.value}
                    attrName={"child_three_dob"}
                    value_update={updateMasterState}
                    warn_status={formData?.child_three_dob?.warning}
                    error_message={"Select 3rd Child DOB"}
                    min_date={25 * 12}
                    max_date={3}
                    date_validation_type="MONTHS"
                    default_date={subDays(new Date(), 90)}
                  />
                </Grid>
              </>
            )}

            {parseInt(formData.son_count.value) +
              parseInt(formData.daughter_count.value) >=
              4 && (
              <>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title={"Child 4 DOB"}
                    value={formData?.child_four_dob?.value}
                    attrName={"child_four_dob"}
                    value_update={updateMasterState}
                    warn_status={formData?.child_four_dob?.warning}
                    error_message={"Select 4th Child DOB"}
                    min_date={25 * 12}
                    max_date={3}
                    date_validation_type="MONTHS"
                    default_date={subDays(new Date(), 90)}
                  />
                </Grid>
              </>
            )}
            <Grid xs={12} textAlign={"center"}>
              <CustomButton
                onClick={() => {
                  validateForm();
                }}
                text="Continue"
                className="primaryBtn mt-3"
                variant={"text"}
                fullWidth={false}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
            className="row"
            sx={{ justifyContent: "center" }}
          >
            <Grid xs={12} sx={{ textAlign: "center" }}>
              <h6 className="pageHeading">Personal Details</h6>
            </Grid>

            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Mobile"}
                value={formData.mobile.value}
                attrName={"mobile"}
                value_update={updateMasterState}
                warn_status={formData.mobile.warning}
                validation_type="NUMBER"
                max_length={10}
                error_message={
                  isEmpty(formData.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile Number"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Pincode"}
                value={formData.pincode.value}
                attrName={"pincode"}
                value_update={updateMasterState}
                warn_status={formData.pincode.warning}
                max_length={6}
                validation_type="NUMBER"
                error_message={
                  isEmpty(formData.pincode.value)
                    ? "Enter Pincode"
                    : "Enter Valid Pincode"
                }
              />
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <CustomButton
                text={"View Quotes"}
                onClick={() => {
                  validateForm();
                }}
                loader={loading}
                className="primaryBtn"
                variant={"text"}
                fullWidth={false}
              />
            </Grid>
            <Grid xs={12} className="popup-Checkbox" textAlign={"center"}>
              <CustomCheckbox
                label={
                  <>
                    By clicking on view quotes you agree to all {""}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(COMMON_ROUTES.TERM_AND_CONDTION, "_blank")
                      }
                    >
                      Terms & Conditions
                    </span>
                  </>
                }
                defaultChecked
                value={true}
                disabled={true}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </Box>
  );
};

export default HealthForm;
