import { createSlice } from "@reduxjs/toolkit";
import { TCarSlice } from "../../../Type/Car/TCarSlice/TCarSlice";
import { CAR_REDUCERS } from "./CarReducer";

const intitialState: TCarSlice = {
  DROPDOWN_DATA: {
    CLAIM_STATUS: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
    MAKE_MODEL_LIST: [],
    POPULAR_MAKE: ["Bajaj", "Honda", "TVS", "Hero", "RoyalEnfield", "KTM"],
    RTO_LIST: [],
    NCB: ["0", "20", "25", "35", "45", "50"],
    FUEL_TYPE_LIST: [],
    MAKE_LIST: [],
    MODEL_LIST: [],
    PREVIOUS_INSURER_LIST: [],
    VARIANT_LIST: [],
  },
  ADD_FORM: {
    owner_name: { value: "", warning: false },
    business_type: "Rollover",
    claimed: "No",
    fuel_type: { value: "", warning: false },
    mobile: { value: "", warning: false },
    make_model: { value: "", warning: false },
    make: { value: "", warning: false },
    model:{ value: "", warning: false },
    prev_ncb: "0",
    previous_insurer: { value: "", warning: false },
    reg_date: { value: null, warning: false },
    reg_no: { value: "", warning: false },
    variant_cc: { value: "", warning: false },
    rto: { value: "", warning: false },
    policy_expiry_date: { value: null, warning: false },
    pincode:{ value: "", warning: false },
  },
};

export const CarSlice = createSlice({
  name: "CAR",
  initialState: intitialState,
  reducers: { ...CAR_REDUCERS },
});
