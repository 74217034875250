import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";
import CarProductContainer from "../../../Container/CarProductContainer/CarProductContainer";

function CarProductPage() {
  return (
    <Box className="responsive-wrapper">
      {/* {isMobile ? <MNavBar /> : <NavBar />} */}
      <NavBar />
      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox carPP">
              <h1 style={{ textAlign: "center" }}>Car Insurance</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <p style={{ textAlign: "justify" }}>
              Government of India has made Third party insurance mandatory under
              the Motor Vehicles Act, 1988. The car insurance offers peace of
              mind for the car owner to drive with out worries. Whether you are
              looking to buy a new car insurance policy, We offer policies to
              cater your needs at incredibly low premiums.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
          alignItems={"center"}
        >
          <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
            <img
              src="./images/car_with_umbrella.svg"
              alt="insurance-policy"
              className="left_info_term"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <h2 style={{ marginBottom: "24px" }}>
              Car Insurance
              <span> that </span>suit <span>your </span> needs
            </h2>

            <Box className="form-box-pp px-2">
              <CarProductContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <Box className="redlightbox">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}
              >
                <Grid xs={12} md={6} className="mb-margin2">
                  <h2 style={{ marginBottom: "12px" }}>
                    <span> What is </span>
                    Car Insurance?
                  </h2>
                  <p style={{ textAlign: "justify" }}>
                    A car insurance is a contract between car owner and a
                    General Insurance Company wherein the insurance company
                    offers financial support to the car owner against the
                    losses/damages or liabilities arises out of the car usage.
                    Car insurance offers car owner to get insurance cover
                    against financial losses caused by accidents, liabilities
                    and even in case of vehicle theft. Government of India has
                    made Third party insurance mandatory under the Motor Vehicle
                    Act, 1988. The car insurance offers peace of mind for the
                    car owner to drive with out worries.
                  </p>
                </Grid>
                <Grid xs={12} md={6} textAlign={"center"}>
                  <img
                    src="./images/car-ins-desc.svg"
                    alt="car-insurance"
                    className="ci-img"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box className="carInsurance-types mb-margin2" marginBottom={"60px"}>
          {/* {isMobile ? (
            <Grid
              container
              columnSpacing={3}
              className="row "
              alignItems={"center"}
              marginBottom={"24px"}>
              <Grid xs={12} md={6} className="mb-margin2">
                <Box className="redlightbox">
                  <Grid container columnSpacing={3} className="row">
                    <Grid xs={12}>
                      <h2
                        style={{ textAlign: "left", marginBottom: "24px" }}
                        className="mb-margin1">
                        <span>Types of </span> Car Insurance <span>Plans</span>
                      </h2>
                      <ul className="redTicklist car_redlist">
                        <li>Comprehensive car insurance</li>
                        <li>Third party insurance</li>
                        <li>Standalone Own Damage</li>
                      </ul>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={12} md={6} textAlign={"center"}>
                <img
                  src="./images/car-crash.svg"
                  alt="car-crash"
                  className="car-crash-img"
                />
              </Grid>
            </Grid>
          ) : (
           
          )} */}
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}
          >
            <Grid xs={12} md={6} textAlign={"center"}>
              <img
                src="./images/car-crash.svg"
                alt="car-crash"
                className="car-crash-img"
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Box className="redlightbox">
                <Grid container columnSpacing={3} className="row">
                  <Grid xs={12}>
                    <h2 style={{ marginBottom: "24px" }} className="mb-margin1">
                      <span>Types of </span> Car Insurance <span>Plans</span>
                    </h2>
                    <ul className="redTicklist car_redlist">
                      <li>Comprehensive car insurance</li>
                      <li>Third party insurance</li>
                      <li>Standalone Own Damage</li>
                    </ul>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Comprehensive <span>Car Insurance Policy</span>{" "}
              </h4>
              <p style={{ marginBottom: "12px", textAlign: "justify" }}>
                Comprehensive Car Insurance policy provides complete insurance
                cover against third party / person/ property liability and for
                damages to your own vehicle. For the customer, there is an
                option to avail a wide range of rider options such as Zero
                depreciation, roadside assistance, personal accident cover and
                more at the cost of an additional premium. Comprehensive car
                insurance provides financial assistance in the following cases.
              </p>
              <ul className="redTicklist">
                <li>Accidental Damages</li>
                <li>Fire Damages</li>
                <li>Car Theft</li>
                <li>Natural disasters like earth quake, flood, cyclone etc.</li>
                <li>Manmade hazards like strikes, riots vandalism etc.</li>
                <li>
                  Third party losses like property damage, bodily injury or
                  death
                </li>
                <li>Personal Accidental Injuries or Death</li>
              </ul>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Third Party <span>Car Insurance</span>{" "}
              </h4>
              <p style={{ textAlign: "justify" }}>
                Third party Car Insurance is a type of Motor Insurance that
                provide insurance coverage against damages occurred to a third
                party or property. Unlike comprehensive Insurance, It does not
                provide cover for insurance policy holder and the car. The
                premium to be paid for the third party car insurance is
                determined by the Insurance Regulatory and Development Authority
                of India ( IRDAI) and is revised on an annual basis. Government
                of India has made Third party Car Insurance mandatory under the
                Motor Vehicles Act, 1988.
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Standalone Own Damage <span>Car Insurance</span>{" "}
              </h4>
              <p style={{ marginBottom: "12px", textAlign: "justify" }}>
                As the name suggests, Standalone Own Damage Car Insurance Policy
                offers coverage for own damage of your vehicle. It is a
                standalone insurance policy and is optional to buy for car
                owners in India unlike a third-party insurance policy which is
                mandatory as per the Motor Vehicles Act of 1988. Unforeseen
                events like earthquake, flood, cyclone or many man made
                disasters like terrorism, riots or vandalism can damage your car
                to a great extend, thereby causing huge repair bills. In this
                scenario, Own Damage policy offers coverage for the expenses
                incurred in the repair and cost of replacement of parts or
                procurement of spare parts.
              </p>
              <p style={{ textAlign: "justify" }}>
                Motor Insurance offers coverage to your vehicles from potential
                risks financially. Motor Insurance Policy offers coverage to the
                vehicle owner / driver against financial losses that may incur
                due to accidents or other kinds of damages. As per the Motor
                Vehicles Act 1988, a valid motor insurance is mandatory in India
                to protect the vehicle owners from potential financial risks.
              </p>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="mb-margin2"
            >
               Comparison <span>between</span> Third Party <span>and </span>
              Comprehensive Car Insurance
            </h2>
          </Grid>
          <Grid xs={12}>
            <table className="comparsion-table">
              <thead>
                <tr>
                  <th>What is Covered ?</th>
                  <th>Third Party</th>
                  <th>Comprehensive</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Third-Party Vehicle Damage</td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Third-Party Property Damage</td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Own Vehicle Damage</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Vehicle Theft</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Fire Damage</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>No Claim Bonus</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Cashless Claim</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Damage from Natural Calamities</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Personal Accident Covered</td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Engine Protection</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <span>Through Add-on</span>
                  </td>
                </tr>
                <tr>
                  <td>Zero Depreciation Cover</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <span>Through Add-on</span>
                  </td>
                </tr>
                <tr>
                  <td>NCB Protection</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <span>Through Add-on</span>
                  </td>
                </tr>
                <tr>
                  <td>Invoice Cover</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <span>Through Add-on</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12} marginBottom={"40px"} className="mb-margin2">
            <h2 style={{ textAlign: "center" }}>
              Key features <span> of Car Insurance </span>
            </h2>
          </Grid>
          <Grid xs={12}>
            <ul className="keyFeature_ci_list">
              <li>
                <img src="./images/ci_features_icon1.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Own Damage Cover</h6>
                  <p style={{ color: COLORS.darkGrey }}>
                    Available under Comprehensive and Standalone plan{" "}
                  </p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon2.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>
                    Third Party Damage Cover
                  </h6>
                  <p style={{ color: COLORS.darkGrey }}>
                    Covers third party injury and property damage
                  </p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon3.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Savings</h6>
                  <p style={{ color: COLORS.darkGrey }}>
                    Upto 80% on Car Insurance Premium*
                  </p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon4.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Car Insurance add-ons</h6>
                  <p style={{ color: COLORS.darkGrey }}>
                    10+ add-ons according to the plan
                  </p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon5.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>
                    Personal Accident Cover
                  </h6>
                  <p style={{ color: COLORS.darkGrey }}>Upto ₹15 Lakh</p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon6.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Cashless Repairs</h6>
                  <p style={{ color: COLORS.darkGrey }}>Available</p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon7.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Car Insurance Premium</h6>
                  <p style={{ color: COLORS.darkGrey }}>
                    Starting @ ₹2072/year*
                  </p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon8.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>
                    Buying/Renewal of Policy
                  </h6>
                  <p style={{ color: COLORS.darkGrey }}>Online & Offline</p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon9.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>No Claim Bonus</h6>
                  <p style={{ color: COLORS.darkGrey }}>Upto 50%</p>
                </Box>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12} md={6} className="buying-online-left-sec">
            <h2 style={{ marginBottom: "24px" }} className="mb-margin1">
              <span> Buying insurance </span>
              Policy online !
            </h2>
            <img
              src="./images/man_with_laptop.svg"
              alt="buying-online"
              className="person-laptop-img"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <ul className="orderedlist_policy_points">
              <li>
                <h6>Selection of Type of Plan:</h6>
                <p>
                  One must analyse his/her car insurance needs and requirements
                  and then finalize the type of plan accordingly.
                </p>
              </li>
              <li>
                <h6>Comparing Different Policies:</h6>
                <p>
                  One must first try to research and compare the policies
                  themselves on online platforms, like Turtlemint, to get a
                  clear picture of what suits best according to their budget and
                  requirements.
                </p>
              </li>
              <li>
                <h6>Checking for IDV and Premiums:</h6>
                <p>
                  IDV stands for Insured Declared Value. It refers to the
                  current market value of one’s car and the maximum claim an
                  insurer will pay him/her in case of car damaged repair or
                  stolen.
                </p>
              </li>
              <li>
                <h6>Looking for the Claim Process: </h6>
                <p>
                  One must opt for an insurance company which has a simple and
                  prompt claim process. This can be checked by comparing the
                  Claim Settlement Ratio (CSR) of different companies. Higher
                  the CSR, simpler the claim process.
                </p>
              </li>
              <li>
                <h6>Asking for Add-on Covers: </h6>
                <p>
                  First of all, add-on covers are available only along with
                  Comprehensive Car Insurance. They expand the coverage of one’s
                  policy but also are an added cost to one’s premium. So, one
                  must choose wisely according to his/her needs.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 style={{ marginBottom: "24px", textAlign: "center" }}>
              <span>Key </span>Considerations <span>Before </span> Purchasing{" "}
              <span>Car Insurance</span>
            </h2>
          </Grid>
          <Grid xs={12}>
            {/* {isMobile ? (
              <Box
                className="key-consideration-box-mobile"
                textAlign={"center"}>
                <ul className="kc_point_list" style={{ marginBottom: "40px" }}>
                  <li>
                    <img src="./images/kc_icon1.svg" alt="" />
                    <p>Insured Declared value</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon2.svg" alt="" />
                    <p>Accident Cover</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon3.svg" alt="" />
                    <p>Cashless facility</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon4.svg" alt="" />
                    <p>Zero Depreciation</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon5.svg" alt="" />
                    <p>Roadside Assistance </p>
                  </li>
                  <li>
                    <img src="./images/kc_icon6.svg" alt="" />
                    <p>Engine Protector</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon7.svg" alt="" />
                    <p>No Claim Bonus</p>
                  </li>
                </ul>
                <img
                  src="./images/kc_car_img.svg"
                  alt=""
                  className="kc_car_img"
                />
              </Box>
            ) : (
              
            )} */}
            <Box className="key-consideration-box">
              <img
                src="./images/kc_car_img.svg"
                alt=""
                className="kc_car_img"
              />
              <ul className="kc_point_list">
                <li>
                  <img src="./images/kc_icon1.svg" alt="" />
                  <p>Insured Declared value</p>
                </li>
                <li>
                  <img src="./images/kc_icon2.svg" alt="" />
                  <p>Accident Cover</p>
                </li>
                <li>
                  <img src="./images/kc_icon3.svg" alt="" />
                  <p>Cashless facility</p>
                </li>
                <li>
                  <img src="./images/kc_icon4.svg" alt="" />
                  <p>Zero Depreciation</p>
                </li>
                <li>
                  <img src="./images/kc_icon5.svg" alt="" />
                  <p>Roadside Assistance </p>
                </li>
                <li>
                  <img src="./images/kc_icon6.svg" alt="" />
                  <p>Engine Protector</p>
                </li>
                <li>
                  <img src="./images/kc_icon7.svg" alt="" />
                  <p>No Claim Bonus</p>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12} marginBottom={"40px"} className="mb-margin1">
            <h2>
              Benefits
              <span> of Buying Car Insurance </span>Policy Online
            </h2>
          </Grid>
          <Grid xs={12}>
            <ul className="buying-benefits-ci-list">
              <li>
                <h6 style={{ marginBottom: "12px" }}>Time saving</h6>
                <p style={{ color: COLORS.darkGrey }}>
                  Our portal provides concrete and reliable information which
                  will aid you to make right decisions.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Zero Paper work</h6>
                <p style={{ color: COLORS.darkGrey }}>
                  As the application is filed digitally in our portal along with
                  the scanned copy of the required documents, the paper work is
                  Zero or minimal.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>
                  Convenient and Time Effective
                </h6>
                <p style={{ color: COLORS.darkGrey }}>
                  Our portal assist you to buy policy at your convenient time.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Payment Reminders</h6>
                <p style={{ color: COLORS.darkGrey }}>
                  Timely remiders will be sent to you at frequent intervals so
                  you will never miss the policy due date.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Pay Premium Online</h6>
                <p style={{ color: COLORS.darkGrey }}>
                  Wide variety of Digital payment options available.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Easy Endorsement</h6>
                <p style={{ color: COLORS.darkGrey }}>
                  Endoresment document can be submitted digitally.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>
                  Soft Copy of the Document
                </h6>
                <p style={{ color: COLORS.darkGrey }}>
                  Digital copy of the Policy document can be received via mail.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Best in class service</h6>
                <p style={{ color: COLORS.darkGrey }}>
                  Professional & personalised service.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12} marginBottom={"40px"} className="mb-margin1">
            <Box className="claim-process-box">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}
              >
                <Grid xs={12} marginBottom={"12px"}>
                  <h2 style={{ marginBottom: "12px" }}>
                    Claim process <span>of car insurance</span>
                  </h2>
                  <p style={{ color: COLORS.black, textAlign: "justify" }}>
                    The car insurance claim process has also become as easy as
                    buying car insurance. You can also claim your insurance
                    offline by connecting with the insurance company
                    representative and inform them about the claim to be made.
                  </p>
                </Grid>
                <Grid xs={12} marginBottom={"12px"}>
                  <h6>You will have to follow the following steps</h6>
                </Grid>
                <Grid xs={12} sm={8} className="mb-margin1">
                  <ul className="redTicklist">
                    <li>
                      Inform us(Muthoot insurance Brokers) about the incident
                    </li>
                    <li>
                      File an FIR in case of theft or loss due to man-made
                      incidents
                    </li>
                    <li>
                      Do not move your vehicle from the spot before the surveyor
                      visits
                    </li>
                    <li>
                      Once the surveyor completes the survey and submits the
                      report, your cashless service option will be available for
                      you to repair your vehicle.
                    </li>
                    <li>
                      Submit the original bills and estimate invoice for
                      reimbursement
                    </li>
                    <li>
                      Submit documents required like policy document or number,
                      RC, driving license, etc.
                    </li>
                    <li>
                      Once the process is completed, the insurance claim will be
                      settled
                    </li>
                  </ul>
                </Grid>
                <Grid xs={12} sm={4} textAlign={"center"}>
                  <img
                    src="./images/claim-process-img.svg"
                    alt="car-insurance"
                    className="claim-process-img"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* {isMobile ? <MFooter /> : } */}
      <Footer />
    </Box>
  );
}

export default CarProductPage;
