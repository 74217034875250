import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function Contact() {
  const isMobile = useIsMobile();

  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });

  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox disclaimer">
              <h1 style={{ textAlign: "center" }}>Contact Us</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="form-section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h2 style={{ textAlign: "center" }}>Get In touch</h2>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={7} textAlign={"center"} className="mb-margin2">
            <img
              src="./images/contact-img.svg"
              alt="contact"
              className="contact-img"
            />
          </Grid>
          <Grid xs={12} md={5} className="formbox">
            <RKTextField
              class_name="inputField mb-6"
              title={"Name"}
              value={formFields.name.value}
              attrName={"name"}
              value_update={updateFieldsState}
              warn_status={formFields.name.warn_status}
            />
            <RKTextField
              class_name="inputField mb-6"
              title={"Mobile"}
              value={formFields.mobile.value}
              attrName={"mobile"}
              value_update={updateFieldsState}
              warn_status={formFields.mobile.warn_status}
            />
            <RKTextField
              class_name="inputField mb-6"
              title={"Email"}
              value={formFields.email.value}
              attrName={"email"}
              value_update={updateFieldsState}
              warn_status={formFields.email.warn_status}
            />
            <TextArea
              class_name="inputField mb-6"
              title={"Message"}
              value={formFields.message.value}
              attrName={"message"}
              value_update={updateFieldsState}
              warn_status={formFields.message.warn_status}
              placeholder="Write here..."
            />
            <CustomButton
              className="primaryBtn"
              text="Submit Details"
              onClick={() => {}}
              fullWidth={false}
              variant="contained"
              disable={true}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="contactDetails-section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h2 style={{ textAlign: "center" }}>For quick help, Contact Us</h2>
          </Grid>
        </Grid>
        {isMobile ? (
          <Grid
            container
            columnSpacing={3}
            className="row"
            alignItems={"center"}
          >
            <Grid xs={12} className="mb-margin2" textAlign={"center"}>
              <img
                src="./images/contact-building.svg"
                alt="buildingcontact"
                className="cb-img"
              />
            </Grid>
            <Grid xs={12}>
              <ul className="mobile_cdlist">
                <li>
                  <span>
                    <AlternateEmailIcon />
                  </span>
                  <Box>
                    {" "}
                    <h6 className="mb-2">Email</h6>
                    <a href="mailto:principalofficer@muthootgroup.com">
                      principalofficer@muthootgroup.com
                    </a>
                  </Box>
                </li>
                <li>
                  <span>
                    <PhoneIcon />
                  </span>
                  <Box>
                    <h6 className="mb-2">Phone</h6>
                    <a href="tel:+91 9826529751">+91 9826529751</a>
                  </Box>
                </li>
                <li>
                  <span>
                    <AccessTimeIcon />
                  </span>
                  <Box>
                    <h6 className="mb-2">Time</h6>
                    <p>10 a.m to 8 p.m on all working days.</p>
                  </Box>
                </li>
              </ul>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            columnSpacing={3}
            className="row"
            alignItems={"center"}
          >
            <Grid xs={6} className="mb-margin2">
              <ul className="cd-list">
                <li>
                  <span>
                    <AlternateEmailIcon />
                  </span>
                  <h6 className="mb-2">Email</h6>
                  <a href="mailto:principalofficer@muthootgroup.com">
                    principalofficer@muthootgroup.com
                  </a>
                </li>
                <li>
                  <span>
                    <PhoneIcon />
                  </span>
                  <h6 className="mb-2">Phone</h6>
                  <a href="tel:+91 9826529751">+91 9826529751</a>
                </li>
                <li>
                  <span>
                    <AccessTimeIcon />
                  </span>
                  <h6 className="mb-2">Time</h6>
                  <p>10 a.m to 8 p.m on all working days.</p>
                </li>
              </ul>
            </Grid>
            <Grid xs={6}>
              <img
                src="./images/contact-building.svg"
                alt="buildingcontact"
                className="cb-img"
              />
            </Grid>
          </Grid>
        )}
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Contact;
