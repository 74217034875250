import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { isEmpty } from "../../../SupportingFiles/HelpingFunction";
import "../FieldTypes.scss";

interface Props {
  attrName: any;
  title: string;
  value: string | Date | null;
  value_update: Function;
  data: Array<any>;
  error_message: string;
  warn_status: boolean;
  class_name: string;
}
const SearchDropdown: React.FC<Props> = ({
  attrName,
  title,
  value,
  data,
  class_name,
  error_message,
  warn_status,
  value_update,
}) => {
  const selectedOption = data.find((option) => option.value === value) || null;

  return (
    <div className={class_name}>
      <Autocomplete
        id={title}
        disablePortal
        options={data}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            value={selectedOption ? selectedOption.label : value}
          />
        )}
        value={selectedOption}
        isOptionEqualToValue={(option, value) => option.value === value}
        onChange={(event, newValue) => {
          if (!isEmpty(newValue?.value)) {
            value_update(attrName, newValue ? newValue.value : null);
          }
        }}
        getOptionLabel={(option) => option.label}
        noOptionsText="No options"
      />
      {warn_status ? <span className="error">{error_message}</span> : null}
    </div>
  );
};

export default SearchDropdown;
