import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";

function TermsCondition() {
  const isMobile = useIsMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header tc">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox career">
              <h1 style={{ textAlign: "center" }}>Terms & Conditions</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="textContent-pages">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>Terms of Use Agreement</h5>
            <p>
              Muthoot Insurance Brokers PVT LTD operates the website ……….
              Muthoot Insurance Brokers PVT LTD is a licensed Insurance Broking
              Company holding a broking license from the Indian Insurance
              Regulator - Insurance Regulatory and Development Authority of
              India bearing IRDAI License No. 459 valid till: 01/09/2025. This
              portal enables users with information and tools to make it easy
              for the customers to compare and buy insurance policies.
            </p>
            <p>
              By visiting the portal ………. and accessing the information,
              resources, services, products, and tools we provide, you
              understand and agree to accept and adhere to the following terms
              of use as stated in this policy (hereafter referred to as 'ToU
              Agreement'), along with the terms and conditions as stated in our
              Privacy Policy.
            </p>
            <p>
              This Terms of Use Agreement is effective from the time you visit
              our website. We reserve the right, at any time, at our sole
              discretion, to change or otherwise modify the Terms of Use
              Agreement without prior notice, and your continued access or use
              of Muthoot Insurance Portal signifies your acceptance of the
              updated or modified Terms of Use Agreement.
            </p>
          </Grid>

          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>
              Using Muthoot Insurance Broker's Portal
            </h5>
            <ul className="content-listpoints">
              <li>
                In order to access our Resources, you may be required to provide
                certain information about yourself (such as name, address,
                contact details, car registration and purchase details, identity
                proof such as Aadhar ID, etc.). You agree that any information
                you provide will always be accurate, correct, and updated.
              </li>
              <li>
                By providing your information you explicitly authorise us to
                access on your behalf your records from the competent authority,
                such as Aadhar, RTO, etc. for the purpose of facilitation of
                your transaction with us.
              </li>
              <li>
                You are responsible for maintaining the confidentiality of any
                login information associated with any account you use to access
                our Resources. Accordingly, you are responsible for all
                activities that occur under your account/s.
              </li>
              <li>
                Accessing (or attempting to access) any of our Resources by any
                means other than through the means we provide is strictly
                prohibited. You specifically agree not to access (or attempt to
                access) any of our Resources through any automated, unethical or
                unconventional means.
              </li>
              <li>
                Engaging in any activity that disrupts or interferes with our
                Resources, including the servers and/or networks to which our
                Resources are located or connected, is strictly prohibited.
              </li>
              <li>
                Attempting to copy, duplicate, reproduce, sell, trade, or resell
                our Resources is strictly prohibited
              </li>
              <li>
                You are solely responsible for any consequences, losses, or
                damages that we may directly or indirectly incur or suffer due
                to any unauthorised activities conducted by you, as explained
                above, and may incur criminal or civil liabilities.
              </li>
            </ul>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>User Generated Content</h5>
            <p>
              We may provide various open communication tools on our website,
              such as blog comments, blog posts, public chat, forums, message
              boards, newsgroups, product ratings and reviews, various social
              media services, etc. You understand that we do not pre-screen
              content posted by users of these various communication tools,
              which means that if you choose to use these tools to submit any
              type of content to our website (“user generated content”), then it
              is your personal responsibility to use these tools in a
              responsible and ethical manner. By posting information or
              otherwise using any open communication tools as mentioned, you
              agree that you will not upload, post, share, or otherwise
              distribute any content that:
            </p>
            <ul className="content-listpoints">
              <li>
                Is illegal, threatening, defamatory, abusive, harassing,
                degrading, intimidating, fraudulent, deceptive, invasive,
                racist, or contains any type of suggestive, inappropriate, or
                explicit language;
              </li>
              <li>
                Infringes on any trademark, patent, trade secret, copyright, or
                other proprietary right of any party;
              </li>
              <li>
                Contains any type of unauthorized or unsolicited advertising;
              </li>
              <li>
                Impersonates any person or entity, including any Muthoot
                Insurance Brokers PVT LTD employees or representatives.
              </li>
            </ul>
            <p>
              We do not assume any liability for any user generated content
              posted by you or any other 3rd party users of our website. We have
              the right at our sole discretion to remove any content that, we
              feel in our judgment does not comply with this ToU Agreement,
              along with any content that we feel is otherwise offensive,
              harmful, objectionable, inaccurate, or violates any 3rd party
              copyrights or trademarks.
            </p>
            <p>
              Any user generated content posted by you using any open
              communication tools on our website, provided that it doesn't
              violate or infringe on any 3rd party copyrights or trademarks,
              becomes the property of Muthoot Insurance Brokers PVT LTD , and as
              such, gives us a perpetual, irrevocable, worldwide, royalty-free,
              exclusive license to reproduce, modify, adapt, translate, publish,
              publicly display and/or distribute as we see fit. This only refers
              and applies to user generated content as described. All user
              Personal Information, provided as part of our registration or
              purchase process, is covered by our Privacy Policy.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>IPR and Copyright</h5>
            <p>
              All content and materials available on Muthoot Insurance Broker's
              Portal, including but not limited to text, graphics, website name,
              code, images and logos are either the intellectual property of
              Muthoot Insurance Brokers Pvt Ltd or under licensed use by Muthoot
              Insurance Brokers, and are protected by applicable copyright and
              trademark law. Any inappropriate use, including but not limited to
              the reproduction, distribution, display or transmission of any
              content on this site is strictly prohibited, unless specifically
              authorized by Muthoot Insurance Brokers Pvt Ltd.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>Indemnification</h5>
            <p>
              You agree to indemnify and hold Muthoot Insurance Brokers Pvt Ltd,
              its parent company, officers, subsidiaries, affiliates,
              successors, assigns, directors, officers, agents, service
              providers, suppliers and employees, harmless from any claim or
              demand, including reasonable attorney fees and court costs, made
              by any third party due to or arising out of content submitted by
              the user, users use of the service, violation of the Terms of Use,
              breach by the user of any of the representations and warranties
              herein, or user’s violation of any rights of another.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>Limitation of Warranties</h5>
            <p>
              By using our website, you understand and agree that all Resources
              we provide are “as is” and “as available”. This means that we do
              not represent or warrant to you that:
            </p>
            <ul className="content-listpoints" style={{ marginBottom: "12px" }}>
              <li>
                The use of our Resources will meet your needs or requirements,
              </li>
              <li>
                The use of our Resources will be uninterrupted, timely, secure
                or free from errors,
              </li>
              <li>
                The information obtained by using our Resources will be accurate
                or reliable, and
              </li>
              <li>
                Any downloads from our Webportal will not harm your computer or
                device in any way.
              </li>
            </ul>
            <p>
              To the maximum extent permitted by law, Muthoot Insurance Brokers
              Pvt Ltd has no liability in relation to or arising out of the
              Website Information and recommendations. You are responsible for
              the final choice of your product. Please seek further advice from
              Muthoot Insurance Brokers Pvt Ltd or the relevant participating
              insurance companies before choosing your product, if you have any
              doubts or queries about the same. Furthermore, Muthoot Insurance
              Brokers Pvt Ltd does not guarantee when or if you will actually
              acquire the product that you have chosen and does not accept any
              liability arising out of delay in you acquiring the product you
              have chosen. You acknowledge and accept that the final issuance of
              the policy is subject to the underwriting norms and sole
              discretion of the insurance company whose policy you have chosen
              to buy.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>Limitation of Liability</h5>
            <p>
              We expressly understand and agree that any claim against us shall
              be limited to the amount you paid, if any.Muthoot Insurance
              Brokers Pvt Ltd will not be liable for any direct, indirect,
              incidental, consequential or exemplary loss or damages which may
              be incurred by you as a result of using our Resources.
            </p>
            <p>
              UNLESS OTHERWISE EXPRESSED, Muthoot Insurance Brokers Pvt Ltd
              EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND,
              WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
              IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5 style={{ marginBottom: "12px" }}>Governing Law</h5>
            <p>
              This Terms of Use Agreement and the Privacy Policy shall be
              governed and construed in accordance with the laws of the Republic
              of India and the courts at Keralai shall have exclusive
              jurisdiction on all matters and disputes arising out of and
              relating to the Site
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TermsCondition;
